import React from "react";
import { Link } from "gatsby";

import { PublicLayout } from "../../layouts/public"

const Page = (props) => {
    const content = {}
    content.htmlTitle = `The Quality Data Co.`
    content.pageTitle = (<div>Contact Us</div>)
    content.pageTagline = (<div>How can we help?</div>)
    content.pageBody = (
        <React.Fragment>
                Shoot an email to <a href="mailto:founders@yolkdata.com">founders@yolkdata.com</a> and we'll be in touch!
           
        </React.Fragment>
    )

    return <PublicLayout {...content} />;
};

export default Page;
